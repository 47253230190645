<template>
  <div>
    <salerComponent />
  </div>
</template>

<script>
import salerComponent from '@/components/community/Saler'

export default {
  components: {
    salerComponent
  }
}
</script>
