<template>
    <Row>
        <Transfer
        style="width:100%"
        :data="Salers"
        :target-keys="CommunitySalers"
        filterable
        :operations="['移除','新增']"
        :titles="['普通销售','公益销售']"
        :list-style="listStyle"
        :filter-method="filterMethod"
        @on-change="handleChange"></Transfer>
    </Row>
</template>

<script>
import { getSellerPage, setCommunity } from '@/api/os/companyuser'

export default {
  name: 'CommunitySaler',
  data () {
    return {
      Salers: [],
      CommunitySalers: [],
      listStyle: {
        width: '40%',
        height: '600px'
      }
    }
  },
  methods: {
    initSalers () {
      const data = {
        companyId: this.$store.getters.token.userInfo.companyId,
        pageNumber: 1,
        pageSize: 100
      }
      getSellerPage(data).then(res => {
        res.list.forEach(res => {
          this.Salers.push({ key: res.id, label: res.name })
          if (res.community) {
            this.CommunitySalers.push(res.id)
          }
        })
      })
    },
    handleChange (newTargetKeys) {
      const oldCommunitySalers = this.CommunitySalers
      const communityJson = []
      newTargetKeys.forEach(key => {
        if (!oldCommunitySalers.includes(key)) {
          communityJson.push({ id: key, community: true })
        } else {
          const index = oldCommunitySalers.indexOf(key)
          oldCommunitySalers.splice(index, 1)
        }
      })
      oldCommunitySalers.forEach(key => {
        communityJson.push({ id: key, community: false })
      })
      const data = {
        communityJson: JSON.stringify(communityJson)
      }
      setCommunity(data).then(res => {
        this.CommunitySalers = newTargetKeys
      })
    },
    filterMethod (data, query) {
      return data.label.indexOf(query) > -1
    }
  },
  created () {
    this.initSalers()
  }

}
</script>
